import { memo } from 'react'

import makeStyles from '@material-ui/core/styles/makeStyles'
import LinearProgress from '@material-ui/core/LinearProgress'

import { useTranslation } from 'react-i18next'

import { LOGO } from '../../constants/AppConstants'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'grid',
        gridTemplateRows: 'max-content max-content',
        gridRowGap: theme.spacing(3),
        justifyItems: 'center',
        alignContent: 'center',
        backgroundColor: theme.palette.background.default,
        height: '100%'
    },
    logo: {
		justify: 'center',
		width: theme.spacing(18),
		height: theme.spacing(18),
		borderStyle: 'ridge',
		borderRadius: theme.spacing(9)
    },
    progress: {
        [theme.breakpoints.down('sm')]: {
            width: '30%'
        },
        [theme.breakpoints.up('sm')]: {
            width: '15%'
        }
    }
}))

const AppLoading = _ => {

	const { t } = useTranslation()

    const classes = useStyles()

    return (
        <div className={classes.root}>
            <img className={classes.logo} src={LOGO} alt={t('appName')} />
            <LinearProgress className={classes.progress} variant='indeterminate' />
        </div>
    )
}

export default memo(AppLoading)
import { useEffect, Suspense, lazy, memo } from 'react'
import { Switch, Route } from 'react-router-dom'

import CssBaseline from '@material-ui/core/CssBaseline'
import useTheme from '@material-ui/core/styles/useTheme'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Portal from '@material-ui/core/Portal'

import { useAppContext } from '../../contexts/AppContext'

import { toggleAppDrawer } from '../../actions/appActions'

import AppSnackbar from './AppSnackbar'
import AppLoading from './AppLoading'

const LoginView = lazy(_ => import('../login/LoginView'))
const MainView = lazy(_ => import('../main/MainView'))
const NotFound = lazy(_ => import('./NotFound'))

function App() {

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })
	const [, dispatchAppAction] = useAppContext()

	useEffect(_ => {

		document.body.style.backgroundColor = theme.palette.background.paper

	}, [theme])

	useEffect(_ => {

		dispatchAppAction(toggleAppDrawer(!isMobile))

	}, [isMobile, dispatchAppAction])

	return (
		<Suspense fallback={<AppLoading />}>
			<CssBaseline />
			<Portal>
				<AppSnackbar />
			</Portal>
			<Switch>
				<Route path='/login' exact>
					<LoginView isMobile={isMobile} />
				</Route>
				<Route path='/' exact>
					<MainView isMobile={isMobile} />
				</Route>
				<Route>
					<NotFound />
				</Route>
			</Switch>
		</Suspense>
	)
}

export default memo(App)

import { THEME_STATE } from '../constants/StateNames'

import { THEME_MODES } from '../constants/Themes'

import { CHANGE_THEME } from '../actions/themeActions'

const initialState = JSON.parse(localStorage.getItem(THEME_STATE)) || {
	theme: window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? THEME_MODES.DARK : THEME_MODES.LIGHT
}

const reducer = (state, action) => {

	switch (action.type) {

		case CHANGE_THEME:

			return {
				...state,
				theme: action.theme
			}

		default: return state
	}
}

export { reducer as themeReducer, initialState as themeInitialState }
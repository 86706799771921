import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import makeStyles from '@material-ui/core/styles/makeStyles'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import Slide from '@material-ui/core/Slide'

import { useAppContext } from '../../contexts/AppContext'
import { toggleSnackbar } from '../../actions/appActions'

const useStyles = makeStyles(theme => ({
    snackbar: {
        paddingBottom: 'env(safe-area-inset-bottom)'
    }
}))

const AppSnackbar = memo(_ => {

    const classes = useStyles()
    const { t } = useTranslation()
    const [appState, dispatchAppAction] = useAppContext()

    const handleClose = (_, reason) => {

        if (reason === 'clickaway') return

        dispatchAppAction(toggleSnackbar({ opened: false }))
    }

    return (
        <Snackbar
            className={classes.snackbar}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            key={appState.snackbar?.message}
            open={appState.snackbar?.opened}
            autoHideDuration={3000}
            TransitionComponent={Slide}
            onClose={handleClose}>
            <Alert
                elevation={6}
                variant='filled'
                onClose={handleClose}
                severity={appState.snackbar?.type}>
                {t(appState.snackbar?.message)}
            </Alert>
        </Snackbar>
    )
})

export default memo(AppSnackbar)

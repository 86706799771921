import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'

import './index.css'

import App from './components/app/App'

import { LanguageContextProvider } from './contexts/LanguageContext'
import { ThemeContextProvider } from './contexts/ThemeContext'
import { UserContextProvider } from './contexts/UserContext'
import { AppContextProvider } from './contexts/AppContext'

import i18next from 'i18next'
import './i18n'

i18next.loadLanguages(['en', 'ar']).then(_ => ReactDOM.render(
	<Router basename={process.env.REACT_APP_VIRTUAL_PATH}>
		<LanguageContextProvider>
			<ThemeContextProvider>
				<UserContextProvider>
					<AppContextProvider>
						<App />
					</AppContextProvider>
				</UserContextProvider>
			</ThemeContextProvider>
		</LanguageContextProvider>
	</Router>, document.getElementById('root')))
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Fetch from 'i18next-fetch-backend'

i18n
	.use(initReactI18next)
	.use(Fetch)
	.init({
		backend: {
			loadPath: `${process.env.REACT_APP_VIRTUAL_PATH}locales/{{lng}}/{{ns}}.json?v=` + new Date().getTime(),
		},
		nsSeparator: '@',
		fallbackLng: 'ar',
		lng: 'ar',
		react: {
			useSuspense: false
		}
	})

export default i18n
import { createContext, useEffect, useReducer, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { create } from 'jss'
import rtl from 'jss-rtl'

import { ThemeProvider, StylesProvider, jssPreset, createMuiTheme } from '@material-ui/core/styles'

import { THEME_STATE } from '../constants/StateNames'
import { THEMES, THEME_MODES } from '../constants/Themes'
import { LANGUAGES } from '../constants/Languages'

import { themeReducer, themeInitialState } from '../reducers/themeReducer'

import { useLanguageContext } from './LanguageContext'
import { changeTheme } from '../actions/themeActions'

const ThemeContext = createContext()

const jss = create({ plugins: [...jssPreset().plugins, rtl()] })

const createTheme = (theme, language) => {

	return createMuiTheme({
		...theme,
		overrides: {
			MuiFilledInput: {
				input: {
					'&::placeholder': {
						fontSize: 14
					}
				}
			},
			MuiTableCell: {
				footer: {
					borderBottom: '0px solid !important'
				}
			},
			MuiTablePagination: {
				root: {
					borderBottom: '0px solid !important'
				}
			},
			MuiLink: {
				button: {
					color: theme.palette.type === THEME_MODES.DARK && '#fafafa !important'
				}
			},
			MuiDialog: {
				paper: {
					backgroundColor: (theme.palette.type === THEME_MODES.DARK ? '#303030' : '#fafafa') + ' !important',
				}
			},
			MuiCardContent: {
				root: {
					'&:last-child': {
						paddingBottom: 16
					}
				}
			},
		},
		direction: language === LANGUAGES.AR ? 'rtl' : 'ltr'
	})
}

const ThemeContextProvider = ({ children }) => {

	const location = useLocation()
	const [themeState, dispatchThemeAction] = useReducer(themeReducer, themeInitialState)
	const [languageState] = useLanguageContext()

	useEffect(_ => {

		localStorage.setItem(THEME_STATE, JSON.stringify(themeState))

	}, [themeState])

	useEffect(_ => {

		document.body.setAttribute('dir', languageState.language === LANGUAGES.AR ? 'rtl' : 'ltr')

	}, [languageState.language])

	useEffect(_ => {

		let queryParams = queryString.parse(location.search)

		let { theme } = queryParams

		theme && Object.values(THEME_MODES).includes(theme) && dispatchThemeAction(changeTheme(theme))

	}, [location])

	return (
		<ThemeContext.Provider value={[themeState, dispatchThemeAction]}>
			<ThemeProvider theme={createTheme(THEMES[themeState.theme.toUpperCase()], languageState.language)}>
				<StylesProvider jss={jss}>
					{children}
				</StylesProvider>
			</ThemeProvider>
		</ThemeContext.Provider>
	)
}

const useThemeContext = _ => {

	return useContext(ThemeContext)
}

export { useThemeContext, ThemeContextProvider }
import { USER_STATE } from '../constants/StateNames'

import { SET_USER_DETAILS, SET_USER_PRIVILEGES } from '../actions/userActions'

const initialState = JSON.parse(localStorage.getItem(USER_STATE)) ||
{
	user: {
		username: null,
		phone: null,
		branches: null
	},
	claims: null
}

const reducer = (state, action) => {

	switch (action.type) {

		case SET_USER_DETAILS:

			return {
				...state,
				user: action.userDetails,
			}

		case SET_USER_PRIVILEGES:

			return {
				...state,
				claims: action.claims
			}

		default: return state
	}
}

export { reducer as userReducer, initialState as userInitialState }
export const SET_USER_DETAILS = 'SET_USER_DETAILS'
export const SET_USER_PRIVILEGES = 'SET_USER_PRIVILEGES'

export const setUserDetails = userDetails => {

    return {
        type: SET_USER_DETAILS,
        userDetails
    }
}

export const setUserClaims = claims => {

    return {
        type: SET_USER_PRIVILEGES,
        claims
    }
}
import red from '@material-ui/core/colors/red'

export const THEME_MODES = {
	LIGHT: 'light',
	DARK: 'dark'
}

export const THEMES = {
	DARK: {
		palette: {
			primary: { main: red[500] },
			secondary: { main: red.A200 },
			type: THEME_MODES.DARK
		},
		typography: {
			fontFamily: 'Roboto, Cairo, Arial, sans-serif',
			body1: {
				color: '#ffffffff'
			}
		}
	},
	LIGHT: {
		palette: {
			primary: { main: red[500] },
			secondary: { main: red.A200 },
			type: THEME_MODES.LIGHT
		},
		typography: {
			fontFamily: 'Roboto, Cairo, Arial, sans-serif',
		}
	}
}
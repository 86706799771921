
import { TOGGLE_APP_DRAWER } from '../actions/appActions'
import { TOGGLE_SNACKBAR } from '../actions/appActions'

const initialState = {
    appDrawer: {
        toggled: false
    },
    snackbar: null
}

const reducer = (state, action) => {

    switch (action.type) {

        case TOGGLE_APP_DRAWER:

            return {
                ...state,
                appDrawer: {
                    toggled: action.toggled
                }
            }

        case TOGGLE_SNACKBAR:

            return {
                ...state,
                snackbar: action.snackbar
            }

        default: return state
    }
}

export { reducer as appReducer, initialState as appInitialState }
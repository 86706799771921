import { createContext, useReducer, useContext, useEffect } from 'react'

import { USER_STATE } from '../constants/StateNames'

import { userReducer, userInitialState } from '../reducers/userReducer'

import { setUserClaims } from '../actions/userActions'

import { useTokenStorageService } from '../hooks/useTokenStorageService'

const UserContext = createContext()

const UserContextProvider = ({ children }) => {

	const [userState, dispatchUserAction] = useReducer(userReducer, userInitialState)

	const { clearToken, getToken } = useTokenStorageService()

	useEffect(_ => {

		localStorage.setItem(USER_STATE, JSON.stringify(userState))

	}, [userState])

	useEffect(() => {

		if ((userState.claims?.length ?? 0) > 0 && (getToken()?.length ?? 0) > 0 && (userState?.user?.username === null || userState?.user?.username === undefined)) {
			clearToken()
			dispatchUserAction(setUserClaims([]))
		}

	}, [userState, clearToken, getToken])

	return (
		<UserContext.Provider value={[userState, dispatchUserAction]}>
			{children}
		</UserContext.Provider>
	)
}

const useUserContext = () => {

	return useContext(UserContext)
}

export { useUserContext, UserContextProvider }
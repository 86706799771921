import { createContext, useReducer, useContext } from 'react'

import { appReducer, appInitialState } from '../reducers/appReducer'

const AppContext = createContext()

const AppContextProvider = ({ children }) => {

    const [appState, dispatchAppAction] = useReducer(appReducer, appInitialState)

    return (
        <AppContext.Provider value={[appState, dispatchAppAction]}>
            {children}
        </AppContext.Provider>
    )
}

const useAppContext = () => {

    return useContext(AppContext)
}

export { useAppContext, AppContextProvider }
import { createContext, useContext, useReducer, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import 'moment/locale/ar'
import moment from 'moment'

import { LANGUAGE_STATE } from '../constants/StateNames'

import { languageReducer, languageInitialState } from '../reducers/languageReducer'
import { changeLanguage } from '../actions/languageActions'
import { LANGUAGES } from '../constants/Languages'

const LanguageContext = createContext()

const LanguageContextProvider = ({ children }) => {

	const { i18n, ready } = useTranslation()
	const location = useLocation()
	const [languageState, dispatchLanguageAction] = useReducer(languageReducer, languageInitialState)

	useEffect(_ => {

		localStorage.setItem(LANGUAGE_STATE, JSON.stringify(languageState))
		moment.locale(languageState.language)

	}, [languageState])

	useEffect(_ => {

		ready && i18n.changeLanguage(languageState.language)

	}, [languageState.language, ready, i18n])

	useEffect(_ => {

		let queryParams = queryString.parse(location.search)

		let { language } = queryParams

		language && Object.values(LANGUAGES).includes(language) && dispatchLanguageAction(changeLanguage(language))

	}, [location])

	return (
		<LanguageContext.Provider value={[languageState, dispatchLanguageAction]}>
			{children}
		</LanguageContext.Provider>
	)
}

const useLanguageContext = () => {

	return useContext(LanguageContext)
}

export { useLanguageContext, LanguageContextProvider }
export const useTokenStorageService = _ => {

	const TOKEN = 'access_token'
	const REFRESH_TOKEN = 'refresh_token'

	const setToken = (tokenObj) => {
		localStorage.setItem(TOKEN, tokenObj)
	}

	const setRefreshToken = (tokenObj) => {
		localStorage.setItem(REFRESH_TOKEN, tokenObj)
	}

	const getToken = _ => localStorage.getItem(TOKEN)

	const getRefreshToken = _ => localStorage.getItem(REFRESH_TOKEN)

	const clearToken = _ => {
		localStorage.removeItem(TOKEN)
		localStorage.removeItem(REFRESH_TOKEN)
	}

	return { setToken, setRefreshToken, getToken, getRefreshToken, clearToken }
}
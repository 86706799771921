import { LANGUAGE_STATE } from '../constants/StateNames'
import { LANGUAGES } from '../constants/Languages'

import { CHANGE_LANGUAGE } from '../actions/languageActions'

const initialState = JSON.parse(localStorage.getItem(LANGUAGE_STATE)) || {
	language: navigator.language?.includes('ar') ? LANGUAGES.AR : LANGUAGES.EN
}

const reducer = (state, action) => {

	switch (action.type) {

		case CHANGE_LANGUAGE:

			return {
				...state,
				language: action.language,
			}

		default: return state
	}
}

export { reducer as languageReducer, initialState as languageInitialState }
export const TOGGLE_APP_DRAWER = 'TOGGLE_APP_DRAWER'
export const TOGGLE_SNACKBAR = 'TOGGLE_SNACKBAR'

export const toggleAppDrawer = toggled => {

    return {
        type: TOGGLE_APP_DRAWER,
        toggled
    }
}

export const toggleSnackbar = snackbar => {

    return {
        type: TOGGLE_SNACKBAR,
        snackbar
    }
}